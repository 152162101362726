import { h } from 'preact';
import classnames from 'classnames';

import SVG from '../../../components/common/SVG';

import style from '../style.scss';

const SRSIcons = () => (
  <div className={classnames(style.MainContainer, style.grid)}>
    <SVG className={classnames(style.svgContainer, style.fullRow)} src="/assets/svg/stadium-find-me.svg" />
    <SVG className={classnames(style.svgContainer, style.fullRow)} src="/assets/svg/circle-zone.svg" />
  </div>
);


export default SRSIcons;
