import store from '../../store';
import { addEventListener, removeListener } from '../../helpers/apis/socketApis';
import { mergeImage, decreasePendingImages, hideImage } from '../../helpers/album/actions';
import { logError } from '../../helpers/apis/logger';

let subscribed = false;

const onNewSnapshot = async snapshot => store.middleware(store, mergeImage, snapshot);

const onSnapshotFail = () => {
  store.middleware(store, decreasePendingImages);
  logError({ message: 'Snapshot failed' });
};

const onHideSnapshot = (snapshotId) => {
  console.warn('Hide Snapshot', snapshotId);
  store.middleware(store, hideImage, snapshotId);
  logError({ message: `Hide snapshot: ${snapshotId}` });
};

export default async (currentUrl) => {
  const shouldListenOnThisUrl = ['/taking-photo', '/album'].includes(currentUrl);

  if (shouldListenOnThisUrl && !subscribed) {
    try {
      await Promise.all([
        addEventListener('newSnapshot', onNewSnapshot),
        addEventListener('snapshotFailed', onSnapshotFail),
        addEventListener('hideSnapshot', onHideSnapshot),
      ]);

      subscribed = true;
    } catch (err) {
      console.error(err);
    }
  } else if (!shouldListenOnThisUrl) {
    try {
      await Promise.all([
        removeListener('newSnapshot', onNewSnapshot),
        removeListener('snapshotFailed', onSnapshotFail),
        removeListener('hideSnapshot', onHideSnapshot),

      ]);

      subscribed = false;
    } catch (err) {
      console.error(err);
    }
  }
};
