import { route as preactRoute } from 'preact-router';

import { isProd, isSafari } from './utils';
import store from '../store';

let forceReload = false;

export const setForceReload = () => {
  if (isProd()) {
    forceReload = true;
  }
};

export const getForceReload = () => forceReload;

export const route = (url, replace = false) => {
  const { isTicketMode } = store.getState();

  // when ticket mode is set to true we want to avoid reloading as its not stored as sticky in redux
  if (!forceReload || isTicketMode) {
    preactRoute(url, replace);
    return;
  }

  const fn = replace === true ? 'replaceState' : 'pushState';

  if (isSafari() || !window.history || !window.history[fn]) {
    window.location.pathname = url;
  } else {
    window.history[fn](null, null, url);
    window.location.reload(true);
  }
};

// we need a way to listen to popstate event and stop bubling to preact-router
// we can't do it if we add an event listener from our components
// we are adding an event listener here and passing event object
// to all our listeners subscribed from components

const subscribers = [];

if (typeof window !== 'undefined') {
  window.addEventListener('popstate', (evt) => {
    subscribers.forEach(sub => sub(evt));
  });
}

export const popStateSubscribe = fn => subscribers.push(fn);
export const popStateUnsubscribe = (fn) => {
  if (subscribers.includes(fn)) {
    subscribers.splice(subscribers.indexOf(fn), 1);
  }
};
