import { pageSize } from './constants';
import { isImageInArray } from './helpers';

export const setImages = ({ album }, images) => ({ album: { ...album, images } });

// used to add images at the end of the array
export const addImages = ({ album }, newImages) => ({
  album: {
    ...album,
    images: [
      ...album.images,
      ...newImages,
    ],
  },
});

// merge new image that can arrive from WS, but can also be present in current state
export const mergeImage = ({ album }, image) => {
  const { images, pending } = album;

  if (!images) {
    return {
      album: {
        ...album,
        pending: pending - 1,
        images: [image],
      },
    };
  }

  if (isImageInArray(image, images)) {
    return {
      album: {
        ...album,
        pending: pending - 1,
      },
    };
  }

  for (let i = 0; i < images.length; i++) {
    if (images[i].index === undefined || image.index > images[i].index) {
      return {
        album: {
          ...album,
          pending: pending - 1,
          images: [
            ...images.slice(0, i),
            image,
            ...images.slice(i, pageSize - i - 1),
          ],
        },
      };
    }
  }

  return {
    album: {
      ...album,
      pending: pending - 1,
      images: [
        ...images,
        image,
      ],
    },
  };
};

// in case we already have images pushed with WS, but we need to merge new images from HTTP response
export const mergeImages = ({ album }, newImages) => {
  const { images } = album;
  const hiddenIds = album?.hiddenIds ?? [];

  if (!images) {
    return {
      album: {
        hiddenIds,
        ...album,
        images: newImages,
      },
    };
  }

  return {
    album: {
      ...album,
      images: [
        ...images,
        ...newImages.filter(img => !isImageInArray(img, images)).slice(0, pageSize),
      ].filter(({ _id }) => !hiddenIds.some((hiddenId) => _id === hiddenId)),
    },
  };
};

export const removeImages = ({ album }) => ({
  album: {
    ...album,
    images: undefined,
    pending: 0,
  },
});

export const setPendingImages = ({ album }, pending) => ({
  album: {
    hiddenIds: album?.hiddenIds ?? [],
    ...album,
    pending,
  },
});

export const decreasePendingImages = ({ album }) => ({
  album: {
    ...album,
    pending: album.pending - 1,
  },
});

export const hideImage = ({ album }, snapshotId) => ({
  album: {
    hiddenIds: (album?.hiddenIds ?? []).push(snapshotId),
    showNSFBModal: true,
    ...album,
    images: [
      ...album.images.filter(({ _id }) => _id !== snapshotId),
    ],
  },
});

export const closeNSFBModal = ({ album }) => ({
  album: {
    ...album,
    showNSFBModal: false,
  },
});
